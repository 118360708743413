import React from 'react';

const Logo = () => (
  <img
    height="50rem"
    alt="Logo"
    src="/static/abrege_immo.svg"
  />
);

export default Logo;
