import Role from './role';

export default class User {
  constructor() {
    this.id = undefined;
    this.username = undefined;
    this.email = undefined;
    /**
     * @type {Role}
     */
    this.role = undefined;
    this.password = undefined;
  }

  fromDto(dto) {
    this.id = dto && dto.id;
    this.username = dto && dto.username;
    this.email = dto && dto.email;
    this.role = dto && new Role().fromDto(dto.role);
    this.created_at = dto && dto.created_at;
    this.updated_at = dto && dto.updated_at;
    return this;
  }

  toDto() {
    return {
      username: this.username,
      email: this.email,
      password: this.password,
    };
  }
}
