import React from 'react';
import PropTypes from 'prop-types';

import { CircularProgress, Backdrop } from '@material-ui/core';
import useStyles from './GlobalLoader.style';

const GlobalLoader = (props) => {
  const { show } = props;
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={show}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

GlobalLoader.propTypes = {
  show: PropTypes.bool,
};

GlobalLoader.defaultProps = {
  show: false,
};

export default GlobalLoader;
