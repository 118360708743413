import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo/Logo';
import { THEMES } from 'src/constants/theme';
import page from 'src/constants/page';

import Account from './Account';
import Notifications from './Notifications';
import Settings from './Settings';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main,
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default,
    } : {},
  },
  toolbar: {
    minHeight: 64,
  },
}));

const TopBar = (props) => {
  const { className, onMobileNavOpen, ...rest } = props;
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <Box
            display="flex"
            alignItems="center"
          >
            <RouterLink to={page.APP.path}>
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box
          ml={2}
          flexGrow={1}
        />
        <Notifications />
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.objectOf(PropTypes.string),
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  className: {},
  onMobileNavOpen: () => {},
};

export default TopBar;
