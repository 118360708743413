import axios from 'axios';
import _ from 'lodash';

/**
 * Add the jwt token to the header of each request
 * @param {string} token
 */
export function setAuthorizationHeader(token) {
  if (token) {
    axios.defaults.headers.common.authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.authorization;
  }
}

/**
 * Parse a strapi error
 * @param {Error} err
 * @return {Error}
 */
export function strapiErrorParser(err) {
  let parsedError = 'Bug detected! Contact the developer';
  try {
    parsedError = Array.isArray(err.response?.data?.message)
      ? err.response?.data?.message[0]?.messages[0]?.message
      : err.response?.data?.message || err.message;
  } catch (error) {
    console.error('strapiErrorParser', error.message);
  }
  return new Error(parsedError);
}

/**
 * convert units from "metric" to "imperial" and vice versa
 * @param {number} measure
 * @param {string} from imperial/metric
 * @param {string} to imperial/metric
 * @returns number
 */
export function weightConverter(measure, from, to) {
  let result = null;
  if (!measure) return measure;
  if (from === to) return measure;

  if (typeof measure !== 'number') throw new Error('"measure" is not a number');
  if (to !== 'imperial' && to !== 'metric') throw new Error('"to" is not "imperial" or "metric"');

  switch (from) {
    case 'metric':
      // return lbs
      result = measure * 2.2;
      break;
    case 'imperial':
      // return kg
      result = measure / 2.2;
      break;
    default:
      throw new Error('"from" is not "imperial" or "metric"');
  }

  return _.round(result, 1);
}

/**
 * convert units from "metric" to "imperial" and vice versa
 * @param {number} measure
 * @param {string} from imperial/metric
 * @param {string} to imperial/metric
 * @returns number
 */
export function measureConverter(measure, from, to) {
  let result = null;
  if (!measure) return measure;
  if (from === to) return measure;

  if (typeof measure !== 'number') throw new Error('"measure" is not a number');
  if (to !== 'imperial' && to !== 'metric') throw new Error('"to" is not "imperial" or "metric"');

  switch (from) {
    case 'metric':
      // return lbs
      result = measure * 3.281;
      break;
    case 'imperial':
      // return kg
      result = measure / 3.281;
      break;
    default:
      throw new Error('"from" is not "imperial" or "metric"');
  }

  return _.round(result, 1);
}
