/* eslint-disable react/no-array-index-key */
import React, {
  Suspense,
  Fragment,
  lazy,
} from 'react';
import {
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';

import DashboardLayout from 'src/layouts/DashboardLayout';

import AuthGuard from 'src/guards/AuthGuard';
import GuestGuard from 'src/guards/GuestGuard';

import Loader from 'src/components/Loader/Loader';
import page from 'src/constants/page';

/**
 * List of guards to be executed before hitting the render
 * @param {React.Component[]} guards
 * @param {() => React.Component} render
 */
const renderGuards = (guards = [], render = () => {}) => {
  const guardsClone = [...guards];
  const Guard = guardsClone.shift(); // get the first el
  if (Guard) {
    return <Guard>{renderGuards(guardsClone, render)}</Guard>;
  }
  return render();
};

export const renderRoutes = (routesArray = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routesArray.map((route, i) => {
        const guards = route.guards || [];
        const Layout = route.layout || Fragment;
        const Component = route.component;
        const { path, exact, routes } = route;

        return (
          <Route
            key={i}
            path={path}
            exact={exact}
            render={(props) => renderGuards(guards, () => (
              <Layout>
                {routes
                  ? renderRoutes(routes)
                  : <Component {...props} />}
              </Layout>
            ))}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    path: page.ERROR.path,
    routes: [
      {
        exact: true,
        path: page.ERROR.NOT_FOUND.path,
        component: lazy(() => import('src/containers/error/NotFoundPage/NotFoundPage')),
      },
      {
        component: () => <Redirect to={page.ERROR.NOT_FOUND.path} />,
      },
    ],
  },
  {
    path: page.AUTH.path,
    guards: [GuestGuard],
    routes: [
      {
        exact: true,
        path: page.AUTH.LOGIN.path,
        component: lazy(() => import('src/containers/auth/LoginPage/LoginPage')),
      },
      {
        component: () => <Redirect to={page.AUTH.LOGIN.path} />,
      },
    ],
  },
  {
    path: page.APP.path,
    guards: [AuthGuard],
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: page.APP.PROFIL.path,
        component: lazy(() => import('src/containers/profil/ProfilPage')),
      },
      {
        exact: true,
        path: page.APP.DASHBOARD.path,
        component: lazy(() => import('src/containers/overview/DashboardPage')),
      },
      {
        path: page.APP.BUILDING.path,
        routes: [
          {
            exact: true,
            path: page.APP.BUILDING.LIST.path,
            component: lazy(() => import('src/containers/building/BuildingListPage')),
          },
          {
            exact: true,
            path: [
              page.APP.BUILDING.NEW.path,
              page.APP.BUILDING.EDIT.path,
            ],
            component: lazy(() => import('src/containers/building/BuildingEditNewPage')),
          },
          {
            exact: true,
            path: page.APP.BUILDING.VIEW.path,
            component: lazy(() => import('src/containers/building/BuildingViewPage')),
          },
          {
            component: () => <Redirect to={page.APP.BUILDING.LIST.path} />,
          },
        ],
      },
      {
        path: page.APP.TENANT.path,
        routes: [
          {
            exact: true,
            path: page.APP.TENANT.LIST.path,
            component: lazy(() => import('src/containers/tenant/TenantListPage')),
          },
          {
            exact: true,
            path: [
              page.APP.TENANT.NEW.path,
              page.APP.TENANT.EDIT.path,
            ],
            component: lazy(() => import('src/containers/tenant/TenantEditNewPage')),
          },
          {
            exact: true,
            path: page.APP.TENANT.VIEW.path,
            component: lazy(() => import('src/containers/tenant/TenantViewPage')),
          },
          {
            component: () => <Redirect to={page.APP.TENANT.LIST.path} />,
          },
        ],
      },
      {
        path: page.APP.OWNER.path,
        routes: [
          {
            exact: true,
            path: page.APP.OWNER.LIST.path,
            component: lazy(() => import('src/containers/owner/OwnerListPage')),
          },
          {
            exact: true,
            path: [
              page.APP.OWNER.NEW.path,
              page.APP.OWNER.EDIT.path,
            ],
            component: lazy(() => import('src/containers/owner/OwnerEditNewPage')),
          },
          {
            exact: true,
            path: page.APP.OWNER.VIEW.path,
            component: lazy(() => import('src/containers/owner/OwnerViewPage')),
          },
          {
            component: () => <Redirect to={page.APP.OWNER.LIST.path} />,
          },
        ],
      },
      {
        component: () => <Redirect to={page.APP.DASHBOARD.path} />,
      },
    ],
  },
  {
    path: '*',
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to={page.AUTH.path} />,
      },
      {
        component: () => <Redirect to={page.ERROR.path} />,
      },
    ],
  },
];

export default routes;
