import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import toasterTypes from 'src/constants/toaster';

const Toaster = (props) => {
  // status: error, info, success, warning
  const {
    show, message, status, onClose,
  } = props;

  return (
    <Snackbar open={show} autoHideDuration={3000} onClose={onClose}>
      <Alert elevation={6} variant="filled" onClose={onClose} severity={status}>
        {message}
      </Alert>
    </Snackbar>
  );
};

Toaster.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string,
  status: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

Toaster.defaultProps = {
  message: '',
  status: toasterTypes.INFO,
};

export default Toaster;
