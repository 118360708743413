import User from './user';

export default class SignIn {
  /**
   * @param {string=} identifier
   * @param {string=} password
   */
  constructor(identifier, password) {
    this.identifier = identifier;
    this.password = password;
  }

  toDto() {
    return {
      identifier: this.identifier,
      password: this.password,
    };
  }

  fromDto(dto) {
    /** @type {string} */
    this.jwt = dto && dto.jwt;
    this.user = dto && new User().fromDto(dto.user);
    return this;
  }
}
