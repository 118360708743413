import axios from 'src/utils/axios';
import keys from 'src/config/keys';
import User from 'src/models/user';
import { strapiErrorParser } from 'src/utils/helper';

/**
 * Get my profil
 */
export const getMeApi = async () => {
  try {
    const { data } = await axios.get(keys.USER_ENDPOINTS.ME);
    return new User().fromDto(data);
  } catch (err) {
    throw strapiErrorParser(err);
  }
};

/**
 *
 * @param {User} user
 */
export const postUserApi = async (user) => {
  try {
    const { data } = await axios.post(keys.USER_ENDPOINTS.POST, user.toDto());
    return new User().fromDto(data);
  } catch (err) {
    throw strapiErrorParser(err);
  }
};

/**
 *
 * @param {User} user
 */
export const putUserApi = async (user) => {
  try {
    const { data } = await axios.put(keys.USER_ENDPOINTS.PUT(user.id), user.toDto());
    return new User().fromDto(data);
  } catch (err) {
    throw strapiErrorParser(err);
  }
};

/**
 * Count companies. Can apply strapi filters
 * ref: https://strapi.io/documentation/v3.x/content-api/parameters.html#available-operators
 * @param {string} filters
 */
const getCountApi = async (filters = '') => {
  try {
    const { data } = await axios.get(`${keys.USER_ENDPOINTS.GET}/count?${filters}`);
    return data;
  } catch (err) {
    throw strapiErrorParser(err);
  }
};

/**
 * Get all companies. Can apply strapi filters
 * ref: https://strapi.io/documentation/v3.x/content-api/parameters.html#available-operators
 * @param {string} filters
 * @return {{count: number, users: User[]]}}
 */
export const getUsersApi = async (filters = '') => {
  try {
    const promise1 = axios.get(`${keys.USER_ENDPOINTS.GET}?${filters}`);
    const promise2 = getCountApi(filters);
    const [{ data }, count] = await Promise.all([promise1, promise2]);
    const users = data.map((d) => new User().fromDto(d));
    return {
      count,
      users,
    };
  } catch (err) {
    throw strapiErrorParser(err);
  }
};

/**
 * Get one user.
 * @param {string} id
 */
export const getUserApi = async (id) => {
  try {
    const { data } = await axios.get(`${keys.USER_ENDPOINTS.GET}/${id}`);
    return new User().fromDto(data);
  } catch (err) {
    throw strapiErrorParser(err);
  }
};
