export const authActions = {
  SET_AUTH: 'SET_AUTH',
};

export const globalActions = {
  THEME: 'THEME',
  RESET: 'RESET',
  SHOW_TOASTER: 'SHOW_TOASTER',
  HIDE_TOASTER: 'HIDE_TOASTER',
  LOADER: 'LOADER',
};
