import React from 'react';
import { capitalCase } from 'change-case';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
  Badge,
  Box,
  Button,
  Popover,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { Settings as SettingsIcon } from 'react-feather';
import { THEMES } from 'src/constants/theme';
import * as actions from 'src/store/actions';

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5,
  },
  popover: {
    width: 320,
    padding: theme.spacing(2),
  },
}));

const Settings = () => {
  const classes = useStyles();
  const myTheme = useSelector((s) => s.global.theme);
  const myMeasure = useSelector((s) => s.global.measure);
  const dispatch = useDispatch();
  const ref = React.useRef(null);
  const [isOpen, setOpen] = React.useState(false);
  const [values, setValues] = React.useState({
    measure: myMeasure,
    theme: myTheme,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  const handleSave = () => {
    setOpen(false);
    dispatch(actions.changeTheme(values.theme));
  };

  return (
    <>
      <Tooltip title="Settings">
        <Badge
          color="secondary"
          variant="dot"
          classes={{ badge: classes.badge }}
        >
          <IconButton
            color="inherit"
            onClick={handleOpen}
            ref={ref}
          >
            <SvgIcon fontSize="small">
              <SettingsIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography
          variant="h4"
          color="textPrimary"
        >
          Parametres
        </Typography>
        <Box mt={2}>
          <TextField
            fullWidth
            label="Thème"
            name="theme"
            onChange={(event) => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <option
                key={theme}
                value={theme}
              >
                {capitalCase(theme)}
              </option>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSave}
          >
            Enregistrer
          </Button>
        </Box>
      </Popover>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
