import { authActions, globalActions } from '../actions/actionsType';

const initialState = {
  id: null,
  username: null,
  email: null,
  role: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.SET_AUTH:
      return {
        ...state,
        id: action.id,
        username: action.username,
        email: action.email,
        role: action.role,
      };
    case globalActions.RESET:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;
