import axios from 'src/utils/axios';
import keys from 'src/config/keys';
import SignIn from 'src/models/signin';
import { strapiErrorParser } from 'src/utils/helper';

/**
 * Sign the user in
 * @param {SignIn} signin sign in credentials
 */
export const postSignInApi = async (signin) => {
  try {
    const { data } = await axios.post(
      keys.AUTH_ENDPOINTS.SIGN_IN,
      signin.toDto(),
    );
    return new SignIn().fromDto(data);
  } catch (err) {
    throw strapiErrorParser(err);
  }
};
