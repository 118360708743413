import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import page from 'src/constants/page';

const GuestGuard = ({ children }) => {
  const isAuthenticated = useSelector((s) => !!s.auth.id);

  if (isAuthenticated) {
    return <Redirect to={page.APP.path} />;
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GuestGuard;
