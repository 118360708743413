import React from 'react';

import {
  List,
  ListSubheader,
  Divider,
  Typography,
} from '@material-ui/core';

import {
  FaceOutlined as FaceOutlinedIcon,
  HomeOutlined as HomeOutlinedIcon,
  DashboardOutlined as DashboardOutlinedIcon,
} from '@material-ui/icons';

import {
  Award as AwardIcon,
} from 'react-feather';

import page from 'src/constants/page';

import './MenuView.scss';
import { useSelector } from 'react-redux';
import MenuItem from './components/MenuItem';

const MenuView = () => {
  const { } = useSelector((s) => s.auth);
  return (
    <div className="navbar-menu">
      <List
        subheader={(
          <ListSubheader
            className="navbar-menu_header-client"
            disableGutters
            disableSticky
          >
            <Typography
              gutterBottom
              variant="h6"
            >
              Vue d&apos;ensemble
            </Typography>
          </ListSubheader>
      )}
      >
        <List disablePadding>
          <MenuItem
            title="Tableau de bord"
            Icon={DashboardOutlinedIcon}
            href={page.APP.DASHBOARD.path}
          />
        </List>
      </List>
      <List
        subheader={(
          <ListSubheader
            className="navbar-menu_header-client"
            disableGutters
            disableSticky
          >
            <Typography
              gutterBottom
              variant="h6"
            >
              Gestion
            </Typography>
          </ListSubheader>
      )}
      >
        <MenuItem
          title="Immeubles"
          Icon={HomeOutlinedIcon}
          href={page.APP.BUILDING.path}
        />
        <MenuItem
          title="Locataires"
          Icon={FaceOutlinedIcon}
          href={page.APP.TENANT.path}
        />
        <MenuItem
          title="Propriétaires"
          Icon={AwardIcon}
          href={page.APP.OWNER.path}
        />
      </List>

    </div>
  );
};

export default MenuView;
