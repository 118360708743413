import { THEMES } from 'src/constants/theme';
import { globalActions } from '../actions/actionsType';
import status from '../../constants/toaster';

const initialState = {
  theme: THEMES.LIGHT,
  isToasterShow: false,
  toasterMessage: null,
  toasterStatus: status.INFO,
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case globalActions.THEME:
      return {
        ...state,
        theme: action.theme,
      };
    case globalActions.SHOW_TOASTER:
      return {
        ...state,
        isToasterShow: true,
        toasterMessage: action.message,
        toasterStatus: action.status,
      };
    case globalActions.HIDE_TOASTER:
      return {
        ...state,
        isToasterShow: false,
      };
    case globalActions.LOADER:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case globalActions.RESET:
      return {
        ...initialState,
        theme: state.theme,
      };
    default:
      return state;
  }
};

export default reducer;
