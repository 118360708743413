import axios from 'axios';

const instance = axios.create({
  timeout: 5000,
});

/**
 * Add token to the header of each request
 * @param {string} token
 */
export const setAuthHeader = (token) => {
  if (token) {
    localStorage.setItem('jwt', token);
    instance.defaults.headers.common.Authorization = token;
  } else {
    localStorage.removeItem('jwt');
    delete instance.defaults.headers.common.Authorization;
  }
};
setAuthHeader(localStorage.getItem('jwt'));

export default instance;
