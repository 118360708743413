import { globalActions } from './actionsType';
import { setAuthHeader } from '../../utils/axios';

const _setTheme = (theme) => ({
  type: globalActions.THEME,
  theme,
});

const _toasterShow = (message, status) => ({
  type: globalActions.SHOW_TOASTER,
  message,
  status,
});

const _toasterHide = () => ({
  type: globalActions.HIDE_TOASTER,
});

const _loader = (isLoading) => ({
  type: globalActions.LOADER,
  isLoading,
});

const _reset = () => ({
  type: globalActions.RESET,
});

//----------------------------------------------------
export const changeTheme = (theme) => (dispatch) => {
  localStorage.setItem('theme', theme);
  dispatch(_setTheme(theme));
};

export const showToaster = (message, status) => (dispatch) => {
  dispatch(_toasterShow(message, status));
};

export const hideToaster = () => (dispatch) => {
  dispatch(_toasterHide());
};

export const loader = (isLoading) => (dispatch) => {
  dispatch(_loader(isLoading));
};

export const reset = () => (dispatch) => {
  setAuthHeader(null); // reset axios header
  dispatch(_reset()); // send RESET to all reducers
};
