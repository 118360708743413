import React from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';

import {
  Divider,
  Drawer,
  Hidden,
  Box,
  Typography,
} from '@material-ui/core';

import page from 'src/constants/page';
import Logo from 'src/components/Logo/Logo';

import useStyles from './NavBar.style';

import UserView from './UserView/UserView';
import MenuView from './MenuView/MenuView';

const NavBar = (props) => {
  const { onMobileClose, openMobile } = props;
  const classes = useStyles();
  const location = useLocation();

  const content = (
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      <div className="navbar_content">
        <Hidden lgUp>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            p={2}
            bgcolor="primary.main"
          >
            <RouterLink to={page.APP.path}>
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <UserView />
        <Divider />
        <MenuView />
        <Divider />
        <Box
          p={2}
          bgcolor="background.dark"
        >
          <Typography
            variant="h6"
            color="textPrimary"
          >
            &copy; Progtech Immobilier
          </Typography>
        </Box>

      </div>
    </PerfectScrollbar>
  );

  React.useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  return (
    <div className="navbar">
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </div>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func.isRequired,
  openMobile: PropTypes.bool.isRequired,
};

export default NavBar;
