import toasterType from 'src/constants/toaster';
import { setAuthHeader } from 'src/utils/axios';
import { postSignInApi } from 'src/api/auth';
import { getMeApi } from 'src/api/user';
import { authActions } from './actionsType';
import {
  showToaster, loader, reset,
} from './global';

const _setAuth = (id, username, email, role) => ({
  type: authActions.SET_AUTH,
  id,
  username,
  email,
  role,
});

//-----------------------------------------------------------

/**
 * Try to autosign in
 */
export const autoSignIn = () => (dispatch) => {
  dispatch(loader(true));
  getMeApi()
    .then((user) => {
      dispatch(
        _setAuth(
          user.id,
          user.username,
          user.email,
          user.role?.name,
        ),
      );
    })
    .catch((err) => {
      // can't auto sign in
    })
    .finally(() => dispatch(loader(false)));
};

/**
 * Sign the user in
 * @param {import('../../models/signin').default} signin
 */
export const signIn = (signin) => (dispatch) => {
  dispatch(loader(true));
  setAuthHeader(null);
  postSignInApi(signin)
    .then((signCredentials) => {
      setAuthHeader(`Bearer ${signCredentials.jwt}`);
      dispatch(
        _setAuth(
          signCredentials.user.id,
          signCredentials.user.username,
          signCredentials.user.email,
          signCredentials.user.role?.name,
        ),
      );
    })
    .catch((err) => {
      dispatch(showToaster(err.message, toasterType.ERROR));
    })
    .finally(() => dispatch(loader(false)));
};

/**
 * Sign the user out
 */
export const signOut = () => (dispatch) => {
  dispatch(reset());
  dispatch(showToaster('You are logged out', toasterType.INFO));
};
