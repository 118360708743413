import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import routes, { renderRoutes } from 'src/routes';
import { createTheme } from 'src/theme';
import ScrollReset from 'src/components/ScrollReset/ScrollReset';
import GlobalStyles from 'src/components/GlobalStyles/GlobalStyles';
import GlobalLoader from 'src/components/GlobalLoader/GlobalLoader';
import GlobalToaster from 'src/components/GlobalToaster/GlobalToaster';

import * as actions from 'src/store/actions';

const App = () => {
  const dispatch = useDispatch();
  const myTheme = useSelector((s) => s.global.theme);
  const {
    isLoading,
    isToasterShow,
    toasterMessage,
    toasterStatus,
  } = useSelector((s) => s.global);

  const theme = createTheme({
    theme: myTheme,
  });

  React.useEffect(() => {
    dispatch(actions.autoSignIn());
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      dispatch(actions.changeTheme(storedTheme));
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <BrowserRouter>
          <GlobalStyles />
          <GlobalToaster
            show={isToasterShow}
            message={toasterMessage}
            status={toasterStatus}
            onClose={() => dispatch(actions.hideToaster())}
          />
          <GlobalLoader show={isLoading} />
          <ScrollReset />
          {renderRoutes(routes)}
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
