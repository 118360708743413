const BASE = process.env.REACT_APP_API;

export default {
  BASE,
  AUTH_ENDPOINTS: {
    SIGN_IN: `${BASE}/auth/local`,
  },
  USER_ENDPOINTS: {
    ME: `${BASE}/users/me`,
    GET: `${BASE}/users`,
    PUT: (id) => `${BASE}/users/${id}`,
    POST: `${BASE}/users`,
  },
  BUILDING_ENDPOINTS: {
    GET: `${BASE}/buildings`,
    PUT: (id) => `${BASE}/buildings/${id}`,
    POST: `${BASE}/buildings`,
  },
  TENANT_ENDPOINTS: {
    GET: `${BASE}/tenants`,
    PUT: (id) => `${BASE}/tenants/${id}`,
    POST: `${BASE}/tenants`,
  },
  OWNER_ENDPOINTS: {
    GET: `${BASE}/owners`,
    PUT: (id) => `${BASE}/owners/${id}`,
    POST: `${BASE}/owners`,
  },
  UPLOAD_ENDPOINT: {
    POST: `${BASE}/upload`,
  },
  TRAILER_ENDPOINT: `${BASE}/trucks`,
};
