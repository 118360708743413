import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import {
  Avatar,
  Typography,
} from '@material-ui/core';

import page from 'src/constants/page';

import './UserView.scss';

const UserView = () => {
  const { username, role } = useSelector((s) => s.auth);

  return (
    <div className="navbar-user">
      <div className="navbar-user_avatar">
        <RouterLink to={page.APP.PROFIL.path}>
          <Avatar
            alt="User"
            className="navbar-user_avatar_img"
          />
        </RouterLink>
      </div>
      <div className="navbar-user_user">
        <Typography
          variant="h5"
          color="textPrimary"
        >
          {username}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
        >
          {role}
        </Typography>
      </div>
    </div>
  );
};

export default UserView;
