import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import page from 'src/constants/page';

const AuthGuard = ({ children }) => {
  const isAuthenticated = useSelector((s) => !!s.auth.id);

  if (!isAuthenticated) {
    return <Redirect to={page.AUTH.path} />;
  }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthGuard;
