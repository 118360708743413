export default {
  ERROR: {
    path: '/error',
    NOT_FOUND: {
      path: '/error/404',
    },
  },
  AUTH: {
    path: '/auth',
    LOGIN: {
      path: '/auth/login',
    },
  },
  APP: {
    path: '/app',
    PROFIL: {
      path: '/app/profil',
    },
    DASHBOARD: {
      path: '/app/dashboard',
    },
    BUILDING: {
      path: '/app/building',
      LIST: {
        path: '/app/building/list',
      },
      EDIT: {
        setPath: (id) => `/app/building/edit/${id}`,
        path: '/app/building/edit/:id',
      },
      NEW: {
        path: '/app/building/new',
      },
      VIEW: {
        setPath: (id) => `/app/building/view/${id}`,
        path: '/app/building/view/:id',
      },
    },
    TENANT: {
      path: '/app/tenant',
      LIST: {
        path: '/app/tenant/list',
      },
      EDIT: {
        setPath: (id) => `/app/tenant/edit/${id}`,
        path: '/app/tenant/edit/:id',
      },
      NEW: {
        path: '/app/tenant/new',
      },
      VIEW: {
        setPath: (id) => `/app/tenant/view/${id}`,
        path: '/app/tenant/view/:id',
      },
    },
    OWNER: {
      path: '/app/owner',
      LIST: {
        path: '/app/owner/list',
      },
      EDIT: {
        setPath: (id) => `/app/owner/edit/${id}`,
        path: '/app/owner/edit/:id',
      },
      NEW: {
        path: '/app/owner/new',
      },
      VIEW: {
        setPath: (id) => `/app/owner/view/${id}`,
        path: '/app/owner/view/:id',
      },
    },
  },
};
